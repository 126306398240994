<script lang="ts">
    import Image from "@lernetz/svelte-image/components/Image.svelte";
    import { Route } from "@lernetz/svelte-router";
    import { t } from "@lernetz/svelte-translate";
    import { get } from "svelte/store";
    import DetailHeader from "../components/DetailHeader.svelte";
    import Material from "../components/Material.svelte";
    import WidthBox from "../components/WidthBox.svelte";
    import type { MaterialSearchResponse } from "../gateway/FlinkGateway";
    import { data, flinkGateway, requests } from "../services";
    import DetailHeaderSubnavigation from "../components/DetailHeaderSubnavigation.svelte";

    export let slug:string;
    let conduction = get(data.conduction);
    let house = conduction.houses.find( house => get(house).slug == slug );
    let image = $house.image;
    let subNavigationItems = [{route: '', title: 'learn-packages'},{route: 'project-description', title: 'project-description'}];
    let collectionMaterialPromise:Promise<MaterialSearchResponse>;

    function getCollectionMaterials() {
        collectionMaterialPromise = flinkGateway.searchCollectionMaterials( get($house.collection)?.publicId, {
          limit: 19,
          offset: 0,
          query: "",
          sort: "material.title:desc",
          filters: {},
          progress: true
        } );
    }

    getCollectionMaterials();

</script>

<svelte:window on:visibilitychange={getCollectionMaterials} />
<DetailHeader padding={false} backlinkText={"houses"} baseSegment={"houses"} subNavigationItems={subNavigationItems} />

<WidthBox paddingBottom={false} paddingHorizontal={false}>
    <div class="mt-80 flex flex-col sm:flex-row sm:h-screen">
        <div class="sm:basis-1/3 sm:mx-0 border-r bg-light-grey border-medium-grey">
            <div class="sm:sticky top-112">
                <div class="flex justify-center ">
                    {#if $house.image}
                        <Image
                            class="w-full"
                            request={requests.assets}
                            vars={{ file_name: $image?.file_name, preset: "large.png" }}
                        />
                    {/if}
                </div>
                <h2 class="px-16 pb-48 md:text-24 lg:text-28 font-bold text-ellipsis overflow-hidden text-center hyphens-auto">{$house.title}</h2>
            </div>
        </div>
        <div class="sm:basis-2/3">
            <div class="sm:hidden flex justify-center h-80 border-b border-t sm:border-t-0 border-medium-grey sticky top-80 bg-white z-50">
                <DetailHeaderSubnavigation subNavigationItems={subNavigationItems}></DetailHeaderSubnavigation>
            </div>
            <Route path="/">
                <div class="mt-32 mx-16 sm:mx-32 lg:mx-48 pb-16 sm:pb-32 lg:pb-48 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-24 gap-y-32 content-center">
                    <Route path="/">
                        {#await collectionMaterialPromise}
                            <div>{$t("loading")}</div>
                        {:then materialSearchResponse}
                            {#each materialSearchResponse.results.sort( (a,b) => get(get(a).material).title > get(get(b).material).title ?  1 : -1  ) as collectionMaterial}
                                <Material model={get(collectionMaterial).material} collection={$house.collection} />
                            {/each}
                        {:catch}
                            <div>{$t("no-learning-packages-found")}</div>
                        {/await}
                    </Route>
                </div>
            </Route>
            <Route path="/project-description">
                <div class="mt-32 mx-16 sm:mx-32 lg:mx-48">
                    <div class="html-content">{@html $house.projectDescription}</div>
                </div>
            </Route>
        </div>
    </div>
</WidthBox>



